<template>
  <div class="spa-modular">
    <p>SAP包含模块</p>
    <div class="items flex">
      <div class="item" v-for="(item,index) in list" :key="index">
        <h2>{{item.title}}</h2>
        <p>{{item.info}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SapModular',
  data() {
    return {
      list: [
        {
          title: 'FI 财务会计',
          info:
            '集中公司有关会计的所有资料，提供完整的文献和全面的资讯，同时作为企业实行控制和规划的最新基础。'
        },
        {
          title: 'TR 财务管理',
          info:
            '一个高效率财务管理完整解决方案，确保公司在世界范围的周转，对财务资产结构实行盈利化组合，并限制风险。'
        },
        {
          title: 'CO 管理会计',
          info:
            '公司管理系统中规划与控制工具的完整体系，具有统一的报表系统，协调公司内部处理业务的内容和过程。'
        },
        {
          title: 'EC 企业控制',
          info: '根据特制的管理资讯，连续监控公司的成功因素和业绩指标。'
        },
        {
          title: 'IM 投资管理',
          info:
            '提供投资手段和专案，从规划到结算的综合性管理和处理，包括投资前分析和折旧模拟。'
        },
        {
          title: ' PP 生产计划',
          info:
            '从重复性生产、订制生产、订装生产，加工制造、批量及订存生产直至过程生产，具有扩展MPRⅡ的功能。另外还可以选择连接PDC、制程控制系统，CAD和PDM。'
        },
        {
          title: 'MM 物料管理',
          info:
            '以工作流程为导向的处理功能对所有采购处理最佳化，可自动评估供应商，透过精确的库存和仓储管理降低采购和仓储成本，并与发票核查相整合。'
        },
        {
          title: 'PM 工厂维护',
          info:
            '集中公司有关会计的所有资料，提供完整的文献和全面的资讯，同时作为企业实行控制和规划的最新基础。'
        },
        {
          title: 'QM 品质管理',
          info:
            '监控、输入和管理整个供应链与品质保证相关的各类处理、协调检查处理、启动校正措施、以及与实验室资讯系统整合。'
        },
        {
          title: 'PS 专案管理',
          info:
            '集中公司有关会计的所有资料，提供完整的文献和全面的资讯，同时作为企业实行控制和规划的最新基础。'
        },
        {
          title: 'SD 销售与分销',
          info:
            '积极支援销售和分销活动，具有出色的定价、订单快速处理、按时交货，交互式多层次可变配置功能，并直接与盈利分析和生产计划模组连接。'
        },
        {
          title: 'HR 人力资源管理',
          info:
            '采用涵盖所有人员管理任务和帮助简化与加速处理的整合式应用程式，为公司提供人力资源规划和管理解决方案。'
        },
        {
          title: '开放式资讯仓储',
          info:
            '包括智能资讯系统，该系统把来自R/3应用程式和外部来源的数据归纳成为主管人员资讯，不仅支援使用者部门决策和控制，同时也支援对成功具有关键作用的高阶控制和监控。'
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.spa-modular {
  margin: 0 auto;
  color: #09152D;
  & > p {
    text-align: center;
    font-size: 29px;
    margin-top: 50px;
    margin-bottom: 90px;
  }
  .items {
    width: 1200px;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
    margin-bottom: 50px;
    .item {
      width: 250px;
      height: 160px;
      margin-bottom: 5px;
      h2 {
        font-size: 18px;
        font-weight: 550;
      }
      p {
        font-size: 12px;
        text-align: left;
        line-height: 28px;
      }
    }
  }
}
</style>
