import axios from '@/utils/axios';

const post = () => {
  return axios.post('/home');
};

const get = () => {
  return axios.get();
};

const http = {
  post,
  get
};
export default http;
