<template>
  <div class="sap">
    <Header>
      <div class="sap-header">
        <div class="sap-begin">
          <p>SAP的起源</p>
          <p>About the SAP</p>
          <p>
            SAP，为“System Applications and
            Products”的简称，是SAP公司的产品——企业管理解决方案的软件名称。SAP公司（纽交所代码：SAP）成立于1972年。
            总部位于德国沃尔多夫市，在全球拥有6万多名员工，遍布全球130个国家，并拥有覆盖全球11,500家企业的合作伙伴网络。
          </p>
          <hr />
          <p>
            <span>SAP起源</span>·
            <span>SAP优点</span>·
            <span>SAP模块</span>
          </p>
        </div>
        <div class="sap-teaminfo">
          <p>我们目前拥有近60人的SAP技术队伍
            核心成员都是从业10年以上</p>
          <hr />
          <p>具有丰富的ECC,S4HANA，BYD,BW,SAC经验</p>
        </div>
        <div class="sap-introduce">
          <img :src="sapIntroduceImg" alt="">
          <p>SAP系统（systems applications and products in data
            processing），又称企业管理解决方案，其功能为：借助软件程序为企业定制并创建管理系统，对企业的人力资源、物流运输、销售服务、交易支付、产品规格及质量、生产活动、原材料采购、货物仓储及库存管理等全部经营活动与环节，实施监督、分析及管理，形成数据化的资源管理系统，为企业生产、决策、组织运营提供指导及依据，有利于企业财务管理质量的提升，有利于企业资金的合理分配。
            <br />
            [1]
            SAP系统是一套企业资源管理软件系统，具有现代化、信息化、智能化的应用优势，能够为企业管理问题的解决提供参考意见，同时可以为企业发展做出系统规划，其在现代化商业发展中的应用也开始受到了诸多企业用户的青睐，其在企业信息化建设中的应用，更是能够简化企业管理流程，节约企业建设资源。
            <br />
            [2]
            采用SAP系统后，与原有的传统财务管理模式相比，企业的财务管理机制得到了创新与提升，对财务管理部门工作人员的专业能力也提出了新的标准与要求，SAP系统的核算难度高、应用范围广，只有财务人员不断提高自身专业素养及工作能力，才能发挥出SAP系统在财务管理工作中的应用优势，降低和防范财务风险。
          </p>
        </div>
      </div>
    </Header>
    <SapAdvantage />
    <SapModular />
  </div>
</template>

<script>
import Header from '@cps/Header.vue';
import SapAdvantage from './SapAdvantage';
import SapModular from './SapModular.vue';
export default {
  name: 'Sap',
  components: { Header, SapAdvantage, SapModular },
  data() {
    return {
      sapIntroduceImg: require('@images/sap/SAP_introduce_txt.png')
    };
  }
};
</script>

<style lang="scss" scoped>
.header-container {
  height: 800px;
}
.header-container::after {
  background-image: url('~@images/sap/SAP_bg.png');
}
.sap {
  .sap-header {
    width: 1200px;
    position: relative;
    .sap-introduce {
      position: absolute;
      width: 1200px;
      height: 600px;
      background-color: #ffffff;
      box-shadow: 0 1px 5px 0 #eeeeee;
      border-radius: 5px;
      top: 290px;
      img {
        width: 1000px;
        margin-top: 80px;
        margin-left: 100px;
      }
      p {
        width: 700px;
        height: 350px;
        text-indent: 28px;
        overflow-y: auto;
        margin-top: 12px;
        margin-left: 100px;
        font-size: 13px;
        line-height: 24px;
        color: #707070;
      }
    }
    .sap-begin {
      width: 260px;
      height: 400px;
      padding-left: 40px;
      padding-right: 100px;
      position: absolute;
      background-image: url('~@images/sap/Origin_bg.png');
      background-position-x: -40px;
      background-color: rgba(255, 255, 255, 0.3);
      top: 59px;
      right: 104px;
      z-index: 1;
      color: #ffffff;
      & > p:nth-child(1) {
        font-size: 34px;
        margin-top: 37px;
      }
      & > p:nth-child(2) {
        font-size: 25px;
        color: #8A8A8A;
        margin-bottom: 20px;
      }
      & > p:nth-child(3) {
        font-size: 14px;
        line-height: 24px;
        color: #f2f2f2;
      }
      & > p:nth-child(5) {
        font-size: 14px;
        line-height: 24px;
        color: #f2f2f2;
        span {
          display: inline-block;
          margin: 0 8px;
          color: #f2f2f2;
        }
      }
      hr {
        margin: 10px 0;
      }
    }
    .sap-teaminfo {
      width: 482px;
      text-align: center;
      color: #ffffff;
      position: absolute;
      top: 100px;
      left: 80px;
      text-shadow: 5px 5px 5px #000000;
      & > p:nth-child(1) {
        font-size: 30px;
      }
      hr {
        width: 250px;
        margin: 20px auto;
      }
    }
  }
}
</style>
