const clone = obj => {
  // Handle the 3 simple types, and null or undefined
  if (obj == null || typeof obj !== 'object') return obj;

  // Handle Date
  if (obj instanceof Date) {
    const copy = new Date();
    copy.setTime(obj.getTime());
    return copy;
  }

  // Handle Array
  if (obj instanceof Array) {
    const copy = [];
    for (let i = 0; i < obj.length; ++i) {
      copy[i] = clone(obj[i]);
    }
    return copy;
  }

  // Handle Object
  if (obj instanceof Object) {
    const copy = {};
    // console.log(obj)
    const keys = Object.keys(obj);
    keys.forEach(key => {
      copy[key] = clone(obj[key]);
    });
    return copy;
  }

  throw new Error("Unable to copy obj! Its type isn't supported.");
};
export default clone;
