<template>
  <div class="profile flex">
    <div class="profile-info flex">
      <div>
        <img :src="profileImg" alt="">
        <p>
          "知向云”是由川渝两地从事信息化开发与咨询服务的技术团队共同投资组建。该团队在过去10多年里，一直专注于商业软件开发、销售、系统集成和相关技术服务。目前，公司拥有近60人的团队，其中SAP技术团队近30人，java技术20余人。主要从事sap软件实施、咨询、运维，移动软件开发，商业智能分析等业务。SAP软件咨询实施是公司的核心业务，未来期望在移动互联，商业智能等领域继续拓展业务
        </p>
      </div>
      <img :src="IntroductionImg" alt="">
    </div>
    <div>
      <el-carousel :autoplay="true" :interval="10000" arrow="always" @change="change"
        height="250px">
        <el-carousel-item v-for="(item,index) in companyHistory" :key="index">
          <div v-show="historyIndex===index" class="profile-carousel-item flex">
            <p>{{item.title}}</p>
            <p class="animated fadeInUp">
              {{item.info}}
            </p>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="profile-history">
      <div class="profile-number">{{year}}</div>
      <el-steps :active="historyIndex" finish-status="finish">
        <el-step v-for="(item,index) in companyHistory" :key="index" :title="item.title"
          icon="el-icon-time" />
      </el-steps>
    </div>
    <p>知向云发展历程</p>
  </div>
</template>

<script>
export default {
  name: 'CompanyProfile',
  data() {
    return {
      profileImg: require('@images/home/Company_profile.png'),
      IntroductionImg: require('@images/home/Introduction_img.png'),
      year: 2018,
      companyHistory: [
        {
          title: '2018 公司成立',
          year: '2018',
          info:
            '多位有识之士，怀揣共同的愿景，依托SAP产品，成立了“知向云”，致力于重庆地区的SAP客户，开展本地化伴随式服务。把SAP推广到更多的本地企业，使其拥有国际一流的信息管理平台，分享更多行业信息管理经验'
        },
        {
          title: '2019年 阿里巴巴合作伙伴',
          year: '2019',
          info:
            '成为阿里巴巴合作伙伴，协同阿里钉钉，开展钉钉重庆区域的部署、定制、开发服务，为重庆区域的中小型企业开展移动办公智能化服务'
        },
        {
          title: '2020年 SAP合作伙伴',
          year: '2020',
          info:
            '成为SAP合作伙伴，向开展SAP咨询实施的本地化服务，让更多重庆客户用得起SAP，用得好SAP的目标，迈出了坚实的一步'
        }
        // {
        //   title: '2021年展望未来',
        //   year: '2021',
        //   info:
        //     ''
        // }
      ],
      historyIndex: 0
    };
  },
  methods: {
    change(index) {
      this.historyIndex = index;
      this.year = this.companyHistory[index].year;
    }
  }
};
</script>

<style lang="scss" scoped>
.profile {
  align-items: center;
  flex-direction: column;
  & > div {
    width: 1000px;
  }
  & > p {
    position: relative;
    color: #09152d;
    font-size: 35px;
    margin: 20px 0;
  }
  & > P::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 70px;
    width: 100px;
    height: 4px;
    background-color: #4586e5;
  }
  .profile-info {
    margin-top: 48px;
    padding-bottom: 20px;
    & > div {
      margin-right: 20px;
    }
    p {
      padding-top: 20px;
      width: 535px;
      font-size: 14px;
      color: #434a58;
      line-height: 29px;
    }
  }
  .profile-history {
    position: relative;
    .profile-number {
      position: absolute;
      font-size: 264px;
      color: #eeeeee;
      opacity: 0.7;
      left: 150px;
      top: -150px;
    }
  }
}

.profile-carousel-item {
  width: 585px;
  height: 200px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p:nth-child(1) {
    color: #09152d;
    font-size: 24px;
    padding-top: 20px;
    padding-bottom: 10px;
  }
  p:nth-child(2) {
    line-height: 29px;
    height: 145px;
    overflow-y: auto;
    padding: 15px;
    padding-top: 0;
    font-size: 16px;
    color: #434a58;
    text-indent: 32px;
    animation-delay: 0.2s;
  }
}

//修改步骤条默认样式
::v-deep .el-step__icon.is-icon {
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

::v-deep .el-step__icon-inner[class*='el-icon']:not(.is-status) {
  font-size: 16px;
}
::v-deep .el-step__title {
  color: #707070;
  font-size: 16px;
}

//修改步骤条被选中默认样式
::v-deep .el-step__title.is-process {
  color: #4586e5;
}
::v-deep .el-step__title.is-finish {
  color: #c0c4cc;
  font-size: 16px;
}

::v-deep .el-step__head.is-finish {
  border-color: #c0c4cc;
  .el-step__icon-inner {
    color: #c0c4cc;
  }
  .el-step__line {
    background-color: #c0c4cc;
  }
}
::v-deep .el-step__head.is-process {
  border-color: #4586e5;
  .el-step__icon-inner {
    color: #4586e5;
  }
  .el-step__line {
    background-color: #4586e5;
  }
}
.el-carousel__item {
  display: flex;
  justify-content: center;
  align-items: center;
}
//覆盖轮播图默认样式
::v-deep .el-carousel__indicator--horizontal {
  display: none;
}
::v-deep .el-carousel__arrow:hover {
  background-color: #4285e7;
}
</style>
