import axios from 'axios';

const instance = axios.create({
  timeout: 60 * 1000,
  withCredentials: true
});

// 添加请求拦截器
instance.interceptors.request.use(
  config => {
    config.baseURL = process.env.VUE_APP_URL;
    // 此处编写请求前自定义拦截事件
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  // 此处编写响应前自定义拦截事件
  response => {
    return response.data;
  },
  error => {
    return Promise.reject(error);
  }
);

export default instance;
