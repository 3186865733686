<template>
  <div class="header-container">
    <div class="header flex">
      <TabBar />
      <slot></slot>
    </div>
  </div>
</template>

<script>
import TabBar from '@cps/TabBar.vue';
export default {
  name: 'Header',
  components: { TabBar },
  data: () => {
    return {};
  },
  methods: {}
};
</script>
<style lang="scss" scoped>
.header {
  position: relative;
  height: 100%;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}
.header-container {
  position: relative;
}
.header-container::after {
  content: '';
  position: absolute;
  // background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 1;
}
</style>
