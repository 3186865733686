<template>
  <div class="tab flex">
    <div class="tab-fliter"></div>
    <img class="logo" :src="logo" alt="" />
    <div>
      <div class="tab-button" @click="$router.push('/')">
        <!-- <div class="filter" :class="$route.name==='Home'?'active':''"></div> -->
        <span class="span-home" :class="$route.name==='Home'?'active':''">首页</span>
      </div>
      <div class="tab-button" @click="$router.push('/sap')">
        <span :class="$route.name==='Sap'?'active':''">SAP介绍</span>
      </div>
      <div class="tab-button" @click="$router.push('/pro')">
        <span :class="$route.name==='Pro'?'active':''">产品介绍</span>
      </div>
      <div class="tab-button" @click="$router.push('/self')">
        <span :class="$route.name==='Self'?'active':''">联系我们</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      logo: require('@images/home/tab_logo.png'),
      src: this.$route
    };
  },
  created() {
    // console.log('src', this.src);
  },
  watch: {}
};
</script>

<style lang="scss" scoped>
.tab {
  margin-top: 40px;
  width: 908px;
  height: 86px;
  border-radius: 2px;
  justify-content: space-around;
  align-items: center;
  .tab-fliter {
    width: 908px;
    height: 86px;
    opacity: 0.9;
    filter: blur(2px);
    background-color: #dddddd;
    position: absolute;
    z-index: -1;
  }
  // .logo {
  //   margin-left: 37px;
  // }

  .tab-button {
    display: inline-block;
    margin-right: 20px;
    width: 108px;
    filter: blur(0.5px);
    span {
      user-select: none;
      display: inline-block;
      width: 139px;
      height: 86px;
      border-radius: 5px;
      text-align: center;
      line-height: 86px;
      font-size: 16px;
      cursor: pointer;
      position: relative;
      z-index: 9;
    }
    .span-home {
      width: 108px;
    }
    span.active,
    span:hover {
      background-image: url('~@images/sap/tab_sel_impression02.png');
      background-position: center;
      // background: linear-gradient(#bed8ff, #4586e5);
      // box-shadow: 0 2px 10px 0 #4586e5;
      color: #ffffff;
    }
    .span-home.active,
    .span-home:hover {
      background-image: url('~@images/home/tab_sel_impression.png');
      background-position: center;
      color: #ffffff;
    }
  }
  .tab-button:nth-child(1) {
    margin-right: -10px;
  }
}
</style>
