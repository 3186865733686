import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import clone from './utils/deepClone';
import http from './api';
import ElementUI from 'element-ui';
import './assets/css/common.scss';

Vue.use(ElementUI);

Vue.prototype.$http = http;
Vue.prototype.$clone = clone;

Vue.config.productionTip = false;

// const vm =
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

// console.log(process.env);
