<template>
  <div class="home">
    <Header>
      <div class="home-header-title">信息数字时代，成就客户的领先者</div>
      <div class="home-header-line"></div>
      <div class="home-header-tip">
        <span>手机网站</span>
        <span>手机APP</span>
        <span>维护运营</span>
      </div>
      <div class="home-header-bottom flex">
        <div class="serviceInfo" v-for="(item,index) in serviceInfo" :key="index">
          <p class="p1">{{item.p1}}</p>
          <p class="p2">{{item.p2}}</p>
        </div>
      </div>
    </Header>
    <!-- 公司简介 -->
    <CompanyProfile />
    <!-- 公司经营理念 -->
    <CompanyPhilosophy />
    <Footer />
  </div>
</template>

<script>
import Header from '@cps/Header.vue';
import Footer from '@cps/Footer.vue';
import CompanyProfile from './CompanyProfile.vue';
import CompanyPhilosophy from './CompanyPhilosophy.vue';

export default {
  name: 'Home',
  components: { Header, CompanyProfile, CompanyPhilosophy, Footer },
  data() {
    return {
      serviceInfo: [
        { p1: '10+', p2: '服务SAP行业企业数' },
        { p1: '50+', p2: '服务JAVA开发公司数' },
        { p1: '20000+', p2: '服务总人数' }
      ]
    };
  }
};
</script>
<style lang="scss" scoped>
.home-header-title {
  z-index: 1;
  color: #ffffff;
  font-size: 39px;
  margin-top: 92px;
  font-family: 'SimHei';
  font-weight: bold;
}
.home-header-line {
  width: 406px;
  margin-top: 20px;
  border-top: 4px solid #1be8ef;
  border-radius: 1px;
}
.home-header-tip {
  margin-top: 16px;
  span {
    padding: 0 10px;
    font-family: 'SimHei';
    font-size: 21px;
    color: #ffffff;
    font-weight: 500;
  }
}
.home-header-bottom {
  width: 100%;
  height: 102px;
  background-color: #030303;
  position: absolute;
  bottom: 0;
  opacity: 0.8;
  justify-content: center;
  align-items: center;
  .serviceInfo {
    padding: 0 80px;
  }
  p {
    color: #e8e8e8;
    text-align: center;
  }
  .p1 {
    font-size: 24px;
  }
  .p2 {
    font-size: 14px;
  }
}
.header-container {
  height: 526px;
}
.header-container::after {
  background-image: url('~@images/home/head_bg.png');
}
</style>
