<template>
  <div class="philosophy">
    <div class="item">
      <div class="business" ref="business">
        <img :src="businessImg" alt="">
        <img class="businessTxtImg" :src="businessTxtImg" alt="">
        <div class="business-bg"></div>
        <div v-show="showCards" class="cards flex">
          <div class="card animated bounceInRight" v-for="(item,index) in cardsList" :key="index">
            <p>{{item.title}}</p>
            <p>{{item.des}}</p>
            <p>{{item.content}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="item advantage flex">
      <div class="container flex">
        <div class="left">
          <img :src="advantageLeftImg" alt="">
        </div>
        <div class="right flex">
          <div v-for="(item,index) in advantageList" :key="index">
            <img :src="item.imgUrl" alt="" />
            <p>{{item.msg}}</p>
          </div>
        </div>
      </div>

    </div>
    <div class="item scope flex">
      <div class="container">
        <p>业务范畴</p>
        <p>The business scope</p>
        <div class="flex">
          <div class="timeline">
            <el-timeline>
              <el-timeline-item v-for="(activity, index) in activities" :key="index"
                :color="activity.color">
                <div @click="tapTimeLine(index)" class="timeline-slot"
                  :class="timelineIndex===index?'active':''" :id="`timeline${index}`">
                  {{activity.content}}</div>
              </el-timeline-item>
            </el-timeline>
          </div>
          <div class="msg" v-for="(item,index) in activities" :key="index"
            v-show="timelineIndex===index">
            <p class="animated bounceIn">{{item.content}}</p>
            <p class="animated fadeInUp">{{item.text}}</p>
          </div>
          <div class="img">
            <img :src="sapImg" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getClientHeight, getScrollTop } from '@/utils/getWindow';
export default {
  name: 'CompanyPhilosophy',
  data() {
    return {
      businessImg: require('@images/home/home_business_eng.png'),
      businessTxtImg: require('@images/home/home_business_txt.png'),
      advantageLeftImg: require('@images/home/home_service_txt.png'),
      sapImg: require('@images/home/home_business_0.png'),
      timelineIndex: 0,
      isThrottle: false,
      showCards: false,
      cardsList: [
        {
          title: '诚信合作',
          des: 'Good faith cooperation',
          content:
            '诚信已经成为当今社会，企业，乃至个人的重要属性指标，只有诚信的人，诚信的企业，才能做出负责任的事。'
        },
        {
          title: '服务至上',
          des: 'The service is supreme',
          content:
            '本地化服务作为我们的发展战略，无形中缩小了我们的服务范围。圈子小，客户的事情搞砸了，没脸在重庆混去！'
        },
        {
          title: '客户第一',
          des: 'Customer first',
          content:
            '我们认为“忧客户之忧，想客户之想 ”把自己当做客户的一份子，更多的站在客户的立场上思考问题、开展工作，才能够真正做到客户第一。'
        }
      ],
      advantageList: [
        {
          imgUrl: require('@images/home/home_service_team.png'),
          msg:
            ' 特别是持续的运维服务，本身是一个长期性的工作内容，对服务对象的企业情况，和系统建设规划以及业务情况的了解深度是非常重要的，这是我们的优势。'
        },
        {
          imgUrl: require('@images/home/home_service_response.png'),
          msg:
            ' 本地化服务，从服务的响应效率，以及服务过程中的沟通都是具有优势的。'
        },
        {
          imgUrl: require('@images/home/home_service_mouth.png'),
          msg:
            '俗话说“好事不出门，坏事传千”偌大的重庆城，IT圈并不大，我们更加注重服务品质，否则“企业没口碑，个人没面子”对服务质量的追求，本身也将成为我们的优势。'
        },
        {
          imgUrl: require('@images/home/home_service_price.png'),
          msg:
            '本地化的服务，在服务成本上也具备一定的优势，没有人员差旅、住宿等问题，一定程度上减轻了客户的费用投入。'
        }
      ],
      activities: [
        {
          content: 'SAP咨询实施',
          color: '#4586e5',
          text:
            '我们目前拥有近30人的SAP技术队伍，核心成员都是从业10年以上，走南闯北的顾问，经验丰富，这对上ECC，S4HANABYD，BW，SAC开展专业服务。'
        },
        {
          content: '阿里钉钉',
          color: '',
          text:
            '阿里钉钉涉及部署、定制、开发、应用几大类服务合作伙伴，我们目前具备部署，定制，开发服务资质，正在努力成为应用服务商'
        },
        {
          content: 'JAVA定制',
          color: '',
          text:
            '我们还拥有一支20人的JAVA开发队伍，涉及前端、后端、产品经理、UI设计，结合公司现有客户力量，为其制作了电商、直播、物流等平台'
        },
        {
          content: '自研产品',
          color: '',
          text:
            '基于钉钉，我们目前逐步着手开展产品的发，例如“彩虹团”、“老板打赏”、“知向云装修”、“知向云党建”等。'
        }
      ],
      clientHeight: 0
    };
  },
  mounted() {
    this.setClientHeight();
    window.addEventListener('resize', this.setClientHeight);
    window.addEventListener('scroll', this.cardsOut);
  },

  methods: {
    tapTimeLine(index) {
      if (this.timelineIndex !== index) {
        this.activities.forEach((item) => {
          item.color = '';
        });
        this.timelineIndex = index;
        this.activities[index].color = '#4586e5';
        this.sapImg = require(`@images/home/home_business_${index}.png`);
      }
    },
    setClientHeight() {
      if (this.timer !== null) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.clientHeight = getClientHeight();
        // console.log('clientHeight', this.clientHeight);
      }, 400);
    },
    cardsOut() {
      if (!this.isThrottle && !this.showCards) {
        const scrollTop = getScrollTop();
        const offsetTop = this.$refs.business.offsetTop;
        // console.log(scrollTop, offsetTop, this.clientHeight);
        (this.clientHeight * 2) / 3 + scrollTop >= offsetTop
          ? (this.showCards = true)
          : (this.showCards = false);
        this.isThrottle = true;
        setTimeout(() => {
          this.isThrottle = false;
        }, 400);
      }
    }
  },

  destroyed() {
    window.removeEventListener('resize', this.setClientHeight);
    window.removeEventListener('scroll', this.cardsOut);
  }
};
</script>

<style lang="scss" scoped>
.philosophy {
  background-color: #ebebeb;
  .business-bg {
    position: relative;
    width: 100%;
    height: 350px;
    background-image: url('~@images/home/home_business_bg.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  .business {
    position: relative;
    margin: 0 auto;
    width: 1200px;
    height: 500px;
    img {
      margin-top: 37px;
    }
    .businessTxtImg {
      position: absolute;
      width: 200px;
      left: 220px;
      top: 160px;
      z-index: 9;
    }
    .cards {
      width: 400px;
      height: 420px;
      position: absolute;
      overflow: hidden;
      right: 100px;
      top: 40px;
      flex-direction: column;
      justify-content: space-between;
      z-index: 9;
      .card {
        box-sizing: border-box;
        padding: 15px 30px;
        width: 100%;
        height: 130px;
        background-color: #ffffff;
        box-shadow: 0px 3px 14px rgba(155, 155, 155, 0.16);
        border-radius: 5px;
        p:nth-child(1) {
          font-size: 16px;
          color: #09152d;
        }
        p:nth-child(2) {
          font-size: 12px;
          color: #bbbcbfff;
          margin-top: 4px;
          margin-bottom: 6px;
        }
        p:nth-child(3) {
          font-size: 12px;
          color: #434a58ff;
        }
      }
      .card:nth-child(2) {
        animation-delay: 0.2s;
      }
      .card:nth-child(3) {
        animation-delay: 0.4s;
      }
    }
  }
  .business::before {
    content: '';
    width: 1100px;
    background: rgba(255, 255, 255, 0.71);
    height: 45px;
    position: absolute;
    left: 50px;
    bottom: 55px;
  }
  .business::after {
    content: '';
    width: 1050px;
    background: rgba(255, 255, 255, 0.5);
    height: 45px;
    position: absolute;
    left: 75px;
    bottom: 30px;
  }
  //服务优势
  .advantage {
    height: 500px;
    background-image: url('~@images/home/home_service_bg.png');
    // background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
    margin-top: 50px;
    justify-content: center;
    align-items: center;
    .container {
      width: 1200px;
    }
    .left {
      width: 384px;
      height: 273px;
      margin-top: 20px;
      margin-right: 50px;
    }
    .right {
      position: relative;
      width: 700px;
      height: 400px;
      background-color: #fff;
      border: 1px solid #707070;
      flex-wrap: wrap;
      & > div {
        width: 50%;
        height: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          margin-top: 20px;
        }
        p {
          margin: 0 30px;
          font-size: 12px;
          line-height: 24px;
          color: #707070ff;
        }
      }
    }
    .right::before {
      content: '';
      background-color: #edededff;
      width: 484px;
      height: 1px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -242px;
    }
    .right::after {
      content: '';
      background-color: #edededff;
      width: 1px;
      height: 158px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -79px;
    }
  }
  .scope {
    height: 500px;
    background-color: #ffffff;
    .container {
      width: 1200px;
      height: 400px;
      margin: auto auto;
      p:nth-child(1) {
        font-size: 30px;
        line-height: 40px;
        color: #09152dff;
        text-align: center;
      }
      p:nth-child(2) {
        font-size: 20px;
        line-height: 26px;
        color: #bbbcbfff;
        text-align: center;
      }
      & > div {
        margin-top: 33px;
        justify-content: space-around;
        .timeline {
          width: 120px;
          margin-top: 30px;
          .timeline-slot {
            cursor: pointer;
          }
          .active {
            color: #4586e5;
            font-weight: bold;
          }
        }
        .msg {
          margin-top: 20px;
          width: 400px;
          p:nth-child(1) {
            font-size: 20px;
            line-height: 26px;
            color: #09152dff;
            text-align: center;
          }
          p:nth-child(2) {
            font-size: 14px;
            line-height: 29px;
            color: #434a58ff;
            text-align: left;
            margin-top: 16px;
            animation-delay: 0.2s;
          }
        }
      }
    }
  }
}
</style>
