import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/home/Home.vue';
import Sap from '../views/sap/Sap.vue';
import Pro from '../views/pro/Pro.vue';
import Self from '../views/self/Self.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    meta: '首页',
    component: Home
  },
  {
    path: '/sap',
    name: 'Sap',
    meta: 'SAP介绍',
    component: Sap
  },
  {
    path: '/pro',
    name: 'Pro',
    meta: '产品介绍',
    component: Pro
  },
  {
    path: '/self',
    name: 'Self',
    meta: '自我介绍',
    component: Self
  }
];

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

const router = new VueRouter({
  routes
});
router.beforeEach((to, from, next) => {
  document.title = to.meta;
  next();
});
export default router;
