<template>
  <div class="partners">
    <p class="partners-title">合作伙伴</p>
    <div class="partners-items flex">
      <div class="partners-item flex" v-for="(item,index) in partnersList" :key="index">
        <img :src="require(`@images/pro/partners_${index}.png`)" alt="">
        <span>{{item.span1}}</span>
        <span>{{item.span2}}</span>
        <span>{{item.span3}}</span>
        <span>{{item.span4}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProPartners',
  data() {
    return {
      partnersList: [
        {
          span1: '业务模式创新',
          span2: 'SAP ERP实施',
          span3: 'SAP SRM实施',
          span4: ''
        },
        {
          span1: '组织架构设计',
          span2: '流程优化',
          span3: 'SAP ERP实施',
          span4: ''
        },
        {
          span1: 'SAP ERP运营维护',
          span2: 'SAP ERP实施',
          span3: 'BPC实施',
          span4: '移动互联产品研发实施'
        },
        {
          span1: '管理与信息化转型',
          span2: '企业绩效管理提升',
          span3: 'SAP ERP实施',
          span4: '电子商务'
        },
        {
          span1: '组织架构设计',
          span2: '流程优化',
          span3: 'SAP ERP实施',
          span4: ''
        },
        {
          span1: '组织架构设计',
          span2: '流程优化',
          span3: 'SAP ERP实施',
          span4: ''
        },
        {
          span1: '组织架构设计',
          span2: '流程优化',
          span3: 'SAP ERP实施',
          span4: ''
        },
        {
          span1: 'SAP ERP运营维护',
          span2: 'SAP ERP推广实施',
          span3: '',
          span4: ''
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.partners {
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 50px;
  .partners-title {
    font-size: 26px;
    color: #000;
    font-weight: bold;
    text-align: center;
    margin-top: 70px;
    margin-bottom: 70px;
  }
  .partners-items {
    width: 1200px;
    flex-wrap: wrap;
    justify-content: space-between;
    .partners-item {
      width: 257px;
      margin-bottom: 20px;
      flex-direction: column;
      align-items: center;
      img {
        border: 1px dashed #000;
        margin-bottom: 10px;
      }
      span {
        display: inline-block;
        color: #1A1717;
        font-size: 16px;
        line-height: 34px;
      }
    }
  }
}
</style>
