<template>
  <div class="pro">
    <Header>
      <p class="pro-header">基于JAVA的定制化业务情况</p>
    </Header>
    <p class="pro-title">他们都在用知向云提供的互联网解决方案</p>
    <div class="pro-items flex">
      <div class="item flex" v-for="(item,index) in proList" :key="index"
        @click="showPro(item,index)">
        <div class="item-1">
          <img :src="item.icon" alt="">
        </div>
        <div class="item-2">{{item.title}}</div>
        <div class="item-3">{{item.info}}</div>
        <div class="item-4">查看产品</div>
      </div>
    </div>
    <ProPartners></ProPartners>
    <el-dialog width="936px" :visible.sync="dialog">
      <div class="dialog-info">
        <h2>{{dialogData.title}}</h2>
        <p>{{dialogData.info}}</p>
        <img :src="dialogData.icon" alt="">
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Header from '@cps/Header.vue';
import ProPartners from './ProPartners.vue';
export default {
  name: 'Pro',
  components: { Header, ProPartners },
  data() {
    return {
      proList: [
        {
          icon: require('@images/pro/01.png'),
          title: '彩虹团',
          info:
            '基于微信公众号的电商平台，主要针对果品批发行业，开展实体店与线上门店的联动销售，打折促销、限时抢购、秒杀单、积分换购、团购拼购等业务场景。'
        },
        {
          icon: require('@images/pro/02.png'),
          title: '虹支付',
          info:
            '基于零售业的支付中台，集合银行卡、微信支付、支付宝、公交卡等多种主流支付渠道的支付中台，主要运用于零售企业的信息化建设。'
        },
        {
          icon: require('@images/pro/03.png'),
          title: '利华码头仓储贸易系统',
          info:
            '根据江津梨花贸易有限公司针对码头业务信息管理需求定制开发的仓储管理系统整体采用B/S 架构，采用pringboot框架，使用Mysql数据库'
        },
        {
          icon: require('@images/pro/04.png'),
          title: '公安局物权保证系统',
          info:
            '根据重庆市公安局刑侦部门针对物证信息管理需求定制开发的物证保全管理系统。整体采用C/S+B/S架构，业务处理词用B/S架构数据库采用ORCALE 11G数据库，采用一级建库分级分权限处理'
        },
        {
          icon: require('@images/pro/05.png'),
          title: '点点餐',
          info:
            '基于微信的小程序，主要用于餐饮行业，外卖点餐，堂食点餐，选品，支付，评论等业务场景。'
        },
        {
          icon: require('@images/pro/06.png'),
          title: '老板打赏',
          info:
            '基于微信或者钉钉的应用服务，主要用于销售型企业，或者是企业的销售部门，开展员工任务打赏，积分抽奖等，激励员工拓展业务，趣味性绩效考核的应用。'
        },
        {
          icon: require('@images/pro/07.png'),
          title: '易智巡',
          info:
            '基于零售和快消行业的，商贸以及连锁超市等进销存系统，拥有大量的客户案例，采购、入库、库存、配送、调拨、销售、促销、结算、盘点、零售等系列功能。（待建设）'
        },
        {
          icon: require('@images/pro/08.png'),
          title: '零售商SRM',
          info:
            '基于移动手机的跨行业，项目管理应用，主要针对项目的启动、交付、项目执行任务的创建、子任务的设立、执行进度、状态、里碑、交付物存档，项目验收等相关事务性管理'
        },
        {
          icon: require('@images/pro/09.png'),
          title: '车管家',
          info:
            '基于JAVA的报表平台，可以外接各种数据源，开展数据库建模，轻量级SQL导向的报表展现和图表展现平台。'
        },
        {
          icon: require('@images/pro/10.png'),
          title: '临时用工平台',
          info:
            '基于钉钉组织架构的党建应用，开展云上非公党建，网上缴纳党费，网上学习党课，浏览党的新闻，开展学习成果记录等。（待建设）'
        },
        {
          icon: require('@images/pro/11.png'),
          title: '知向云项目管理',
          info:
            '基于移动手机的跨行业，项目管理应用，主要针对项目的启动、交付、项目执行任务的创建、子任务的设立、执行进度、状态、里程碑、交付物存档，项目验收等相关事务性管理。（建设中）'
        },
        {
          icon: require('@images/pro/09.png'),
          title: '知向云报表',
          info:
            '基于JAVA的报表平台，可以外接各种数据源，开展数据库建模，轻量级SQL导向的报表展现和图表展现平台。（一期验收）'
        }
      ],
      dialog: false,
      dialogData: {}
    };
  },
  methods: {
    showPro(item, index) {
      this.dialog = true;
      // console.log(item);
      this.dialogData.icon = require(`@images/pic/pro_${index + 1}.png`);
      this.dialogData.title = item.title;
      this.dialogData.info = item.info;
    }
  }
};
</script>
<style lang="scss" scoped>
.pro-header {
  font-size: 30px;
  color: #ffffff;
  margin-top: 81px;
}
.pro-title {
  font-size: 27px;
  text-align: center;
  color: #707070;
  margin-top: 61px;
  margin-bottom: 40px;
}
.pro-items {
  color: #707070;
  width: 1200px;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  .item:hover {
    background-color: #f2f2f2;
  }
  .item {
    cursor: pointer;
    width: 257px;
    height: 356px;
    border: 1px solid #707070;
    border-radius: 10px;
    background-color: #ffffff;
    margin-bottom: 28px;
    flex-direction: column;
    align-items: center;
    .item-1 {
      margin-top: 30px;
      margin-bottom: 20px;
      img {
        width: 90px;
        height: 90px;
      }
    }
    .item-2 {
      font-size: 19px;
      margin-bottom: 13px;
      color: #09152D;
    }
    .item-3 {
      font-size: 12px;
      line-height: 19px;
      color: #434A58;
      width: 210px;
      height: 105px;
    }
    .item-4 {
      width: 130px;
      height: 40px;
      line-height: 40px;
      border: 1px solid #707070;
      color: #707070;
      border-radius: 2px;
      text-align: center;
      cursor: pointer;
    }
  }
}
.dialog-info {
  margin: 0 auto;
  width: 666px;
  color: #434A58;
  h2 {
    text-align: center;
    font-size: 26px;
  }
  p {
    font-size: 18px;
    line-height: 36px;
    margin: 32px 0;
  }
}
.header-container {
  height: 462px;
}
.header-container::after {
  background-image: url('~@images/pro/product_bg.png');
}
</style>
