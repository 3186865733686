<template>
  <div class="sap-advantage" :style="{'backgroundPositionY':size+'px'}">
    <div class="container flex">
      <p>SAP优点</p>
      <p>
        SAP系统的实用性强，接近国际化的发展思想，在加上SAP系统引进了ERP系统的管理思想，将企业的构建模式与先进的理理念相结合，进而运用到实际的经营管理系统中，能够为企业的财务管理工作提供一定的方便。对于管理流程进行优化，还能够优化组织结构与制造留出层，进而利用SAP系统中的财务版块，建立风险预警机制，通过事前风险预测、事中延伸管理及事后的监督分析，保证各项业务的顺利展开。
      </p>
      <div class="items flex">
        <div class="item flex" v-for="(item,index) in list" :key="index">
          <img class="icon" :src="item.icon" alt="">
          <h2>{{item.title}}</h2>
          <p>{{item.info}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getScrollTop } from '../../utils/getWindow';
export default {
  name: 'SapAdvantage',
  data() {
    return {
      top: 0,
      size: 0,
      list: [
        {
          icon: require('@images/sap/SAP_01.png'),
          title: '应用广泛',
          info:
            '应用最广的产品。它集成性好，财务、物资、项目、设备、人力资源等等功能都具备；'
        },
        {
          icon: require('@images/sap/SAP_02.png'),
          title: '架构细致',
          info:
            'SAP是所有ERP产品中对企业构架和财务控制考虑得最细致的系统，是整体控制逻辑和整体系统结构最严谨的系统'
        },
        {
          icon: require('@images/sap/SAP_03.png'),
          title: '适用性强',
          info: '对产品在各种行业的适用性考虑得最多的系统，既应用的行业最广；'
        },
        {
          icon: require('@images/sap/SAP_04.png'),
          title: '定制化服务',
          info:
            'SAP有针对不同行业的解决方案，如：SAP Business One，SAP All-in-One等'
        },
        {
          icon: require('@images/sap/SAP_05.png'),
          title: '事前控制',
          info: '可以进行事前很好的控制，国内软件一般都是事后控制。'
        },
        {
          icon: require('@images/sap/SAP_06.png'),
          title: '稳定性强',
          info: 'SAP系统是整体稳定性最好的系统，是所有ERP产品中最完善的系统'
        }
      ]
    };
  },
  mounted() {
    window.addEventListener('scroll', this.getPostition);
  },
  destroyed() {
    window.removeEventListener('scroll', this.getPostition);
  },
  methods: {
    setPostition() {
      const scrollTop = getScrollTop();
      const speed = 10;
      if (this.top < scrollTop && this.size > -1150) this.size -= speed;
      if (this.top > scrollTop && this.size <= -10) this.size += speed;
      this.top = scrollTop;
    },
    getPostition() {
      if (!this.isThrottle) {
        this.isThrottle = true;
        this.setPostition();
        setTimeout(() => {
          this.isThrottle = false;
        }, 1000 / 60);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.sap-advantage {
  margin-top: 226px;
  height: 650px;
  background-image: url('~@images/pic/SAP_advantages_bg.png');
  background-position-x: center;
  // background-position-y: -200px;
  background-repeat: no-repeat;
  position: relative;
  z-index: -1;
  .container {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    position: relative;
    z-index: 9;
    flex-direction: column;
    align-items: center;
    color: #ffffff;
    p:nth-child(1) {
      font-size: 29px;
      line-height: 28px;
      margin: 36px 0;
    }
    p:nth-child(2) {
      width: 750px;
      text-align: center;
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 20px;
    }
    .items {
      width: 700px;
      height: 420px;
      justify-content: space-around;
      flex-wrap: wrap;
      .item {
        width: 220px;
        height: 197px;
        flex-direction: column;
        align-items: center;
        background-color: #ffffff;
        border-radius: 3px;
        color: #09152D;
        position: relative;
        .icon {
          position: absolute;
          top: 10px;
          left: 10px;
        }
        h2 {
          font-size: 16px;
          margin-top: 25px;
          margin-bottom: 30px;
          font-weight: 500;
        }
        p {
          width: 182px;
          font-size: 12px;
          line-height: 24px;
          text-align: left;
        }
      }
    }
  }
}
.sap-advantage::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
