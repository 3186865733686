const docB = document.body;
const docE = document.documentElement;
let isThrottle = false;

export const getScrollTop = () => {
  const docBtop = docB ? docB.scrollTop : 0;
  const docEtop = docE ? docE.scrollTop : 0;
  return docBtop - docEtop > 0 ? docBtop : docEtop;
};

export const getScrollHeight = () => {
  const docBheight = docB ? docB.scrollHeight : 0;
  const docEheight = docE ? docE.scrollHeight : 0;
  return docBheight - docEheight > 0 ? docBheight : docEheight;
};

export const getClientHeight = () => {
  const mode = document.compatMode;
  return mode === 'CSS1Compat' ? docE.clientHeight : docB.clientHeight;
};

// 节流函数
export const throttle = (fn, time = 20) => {
  const newFn = () => {
    if (!isThrottle) {
      isThrottle = true;
      if (typeof fn !== 'function') return;
      // 执行自定义事件
      fn();
      setTimeout(() => {
        isThrottle = false;
      }, time);
    }
  };
  return newFn;
};

// 防抖函数
export const debounce = (fn, wait = 100) => {
  let timer = null;
  return function() {
    if (timer !== null) {
      clearTimeout(timer);
    }
    timer = setTimeout(fn, wait);
  };
};
