<template>
  <div class="footer">
    <div class="details flex">
      <div>
        <img :src="bottomLogo" alt="">
      </div>
      <div class="link">
        <b>相关链接</b>
        <p @click="$router.push('/sap')">SAP介绍</p>
        <p @click="$router.push('/pro')">客户案例</p>
        <p @click="$router.push('/self')">联系我们</p>
      </div>
      <div class="liaison">
        <b>联系方式</b>
        <p>联系人：满鹏飞</p>
        <p>手机：139-8373-5737</p>
        <p>邮箱：pengfei.man@chqinfo.com</p>
        <p>地址：重庆市渝中区民权路28号英里国际金融中心1207号</p>
      </div>
      <div class="qr">
        <img :src="qrImg" alt="">
      </div>
    </div>
    <p class="icp">渝ICP备19002239</p>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      bottomLogo: require('@images/home/bottom_logo.png'),
      qrImg: require('@images/home/home_code.jpg')
    };
  }
};
</script>

<style lang="scss" scoped>
.footer {
  height: 255px;
  background-color: #3a3a3a;
  .details {
    padding-top: 55px;
    margin: 0 auto;
    width: 1200px;
    height: 150px;
    border-bottom: 1px solid #707070ff;
    color: #ffffff;
    justify-content: space-around;
    .link,
    .liaison {
      b {
        display: block;
        font-size: 16px;
        margin-bottom: 10px;
      }
      p {
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 5px;
        cursor: pointer;
      }
      p:hover {
        color: #4586e5;
      }
    }
  }
  .icp {
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
    text-align: center;
    margin-top: 15px;
  }
  .qr {
    img {
      width: 72px;
      height: 72px;
    }
  }
}
</style>
