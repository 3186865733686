<template>
  <div class="self">
    <Header />
    <p class="self-title1">联系我们</p>
    <p class="self-title2">Contact us</p>
    <div class="self-address flex">
      <BaiduMap class="self-address-map" ak="fsItKoiZpfOGpl9PFtE9Y9BXFyd9MTx1" :center="center"
        :zoom="13" :scroll-wheel-zoom="true">
        <BmMarker :position="center" :dragging="true" animation="BMAP_ANIMATION_BOUNCE" />
      </BaiduMap>
      <div class="self-address-info">
        <div v-for="(item,index) in infoList" :key="index">
          <p v-show="item.name" class="item-name">
            <span class="item-img"><img :src="item.nameIcon" alt=""></span>
            <span :class="{'bold':item.bold}">{{item.name}}</span>
          </p>
          <p v-show="item.photo" class="item-photo">
            <span class="item-img"><img :src="item.photoIcon" alt=""></span>
            <span :class="{'bold':item.bold}">{{item.photo}}</span>
          </p>
          <p v-show="item.email" class="item-email">
            <span class="item-img"><img :src="item.emailIcon" alt=""></span>
            <span>{{item.email}}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@cps/Header.vue';
import { BaiduMap, BmMarker } from 'vue-baidu-map';
export default {
  name: 'Self',
  components: { Header, BaiduMap, BmMarker },
  data() {
    return {
      center: { lng: 106.58168, lat: 29.561969 },
      zoom: 13,
      infoList: [
        {
          name: '满鹏飞',
          photo: '139-8373-5737',
          email: 'pengfei.man@chqinfo.com',
          nameIcon: require('@images/self/contact_icon_head.png'),
          photoIcon: require('@images/self/contact_icon_photo.png'),
          emailIcon: require('@images/self/contact_icon_email.png'),
          bold: true
        },
        {
          name: '联系人：张黎',
          photo: '联系电话：131-4026-1658',
          email: ''
        },
        {
          name: '联系人：张子虎',
          photo: '联系电话：158-2380-6036',
          email: ''
        },
        {
          name: '联系人：王喆',
          photo: '联系电话：158-2380-6036'
        },
        {
          name: '地址:重庆市渝中区民权路28号英利国际金融中心1207号'
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.header-container {
  height: 462px;
}
.header-container::after {
  background-image: url('~@images/self/contact_bg.png');
}
.self {
  .self-title1 {
    text-align: center;
    margin-top: 56px;
    font-size: 30px;
    color: #09152D;
  }
  .self-title2 {
    text-align: center;
    font-size: 20px;
    color: #BBBCBF;
  }
  .self-address {
    width: 1200px;
    margin: 0 auto;
    margin-top: 35px;
    margin-bottom: 128px;
    justify-content: space-between;
  }
  .self-address-map {
    width: 600px;
    height: 400px;
    margin-top: 30px;
  }
  .self-address-info {
    width: 500px;
    height: 400px;
    p {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #434A58;
      margin-bottom: 5px;
      span.item-img {
        display: inline-block;
        width: 30px;
      }
      span.bold {
        font-weight: bold;
      }
    }
    p.item-name {
      margin-top: 25px;
    }
  }
}
</style>
